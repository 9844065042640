<template>
  <div :class="[classes.root, 'container']">
    <div :class="classes.contentWrapper">
      <div :class="classes.content">
        <div :class="classes.inner">
          <div :class="classes.text">
            <div :class="classes.textWrapper">
              <div :class="classes.title">
                Смартфон <br class="xl:hidden">в подписку
              </div>
              <ul :class="classes.ul">
                <li :class="[classes.li, 'gradient']">
                  <span :class="[classes.liNumber, 'gradientNumber', 'mr-[25px]']">1</span>Пользуйся последними <br class="xl:hidden"> моделями без переплат
                </li>
                <li :class="[classes.li, 'gradient']">
                  <span :class="[classes.liNumber, 'gradientNumber']">2</span>Обновляй на лучшую <br class="xl:hidden"> модель по истечению срока
                </li>
              </ul>
            </div>
            <BaseStories :class="classes.stories" />
          </div>
          <div :class="classes.buttonGroup">
            <BaseButton
              type="nuxt-link"
              intent="base-primary"
              size="middle"
              :class="classes.button"
              url="/phone-i?c_subscription=1"
              @click="emitYandexMetrika(YANDEX_METRIKA_GOALS.PODPISKA_GLAVNAYA_CTA)"
            >
              Перейти в каталог
            </BaseButton>
            <BaseButton
              type="nuxt-link"
              intent="link"
              underline="on"
              :class="classes.link"
              :url="{ name: ROUTE_NAMES.SUBSCRIPTION_DESCRIPTION }"
              @click="emitYandexMetrika(YANDEX_METRIKA_GOALS.PODPISKA_GLAVNAYA_CTA_V3)"
            >
              Подробнее о подписке
            </BaseButton>
          </div>
        </div>
      </div>
      <span :class="[classes.coin0, 'coin0', 'xl:hidden']"></span>
      <span :class="[classes.girl]"></span>
    </div>
    <div
      v-if="xlAndLarger"
      :class="classes.tradein"
    >
      <div :class="classes.tradeinTextBlock">
        <h2 :class="classes.tradeinTitle">
          Выкупим ваш <br class="hidden sm:block"> смартфон
        </h2>
        <p :class="classes.tradeinDescription">
          Без торга, оплата сразу. <br> Коробка и чеки не требуются.
        </p>
      </div>
      <BaseButton
        :class="classes.tradeinButton"
        type="a"
        intent="base-blue"
        size="middle"
        target="_blank"
        :href="telegramLink"
        @click="emitYandexMetrika(YANDEX_METRIKA_GOALS.SOCIAL_MEDIA_LINK_CLICK); trackEvent('tg')"
      >
        Продать смартфон
        <span :class="classes.tradeinTgIcon"></span>
      </BaseButton>
      <span :class="classes.tradeinS1"></span>
      <span :class="classes.tradeinS2"></span>
      <span :class="classes.tradeinCoin1"></span>
      <span :class="classes.tradeinCoin2"></span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { cx } from 'cva'
import { ROUTE_NAMES } from '@/constants'
import { emitYandexMetrika, YANDEX_METRIKA_GOALS } from '@/utils'

const classes = {
  root: cx([
    'grid',
    'grid-rows-[repeat(2,_auto)]',
    'gap-[8px]',
    'xl:gap-[12px]',
    'mt-4',
    'mb-[60px]',
    'xl:grid-rows-none',
    'xl:grid-cols-[800px_340px]',
    'xl-mid:grid-cols-[849px_383px]',
    'xl:mt-6',
    'xl:mb-9',
  ]),
  contentWrapper: cx([
    'bg-gradient-hero',
    'h-[492px]',
    'rounded-2xl',
    's:h-[530px]',
    'xl:h-auto',
    'overflow-hidden',
    'relative',
    'z-[1]',
  ]),
  content: cx([
    'h-full',
    'p-[24px]',
    'xl:p-[36px]',
    'xl:bg-no-repeat',
    'xl:bg-[100%_100%]',
    'xl:bg-[length:48%]',
    'xl-mid:bg-[100%_66%]',
    'xl-mid:bg-[length:46%]',
    '2xl:bg-[100%_66%]',
    'xl:bg-[url("/images/section-hero/hero-image-variant-B-2-0.png")]',
  ]),
  inner: cx([
    'grid',
    'grid-rows-2',
    'gap-[305px]',
    's:gap-[345px]',
    'xl:gap-0',
    'h-full',
    'xl:grid-rows-[min-content_1fr_auto]',
  ]),
  text: cx([
    'flex',
    'flex-col',
    'items-center',
    'xl:items-start',
    'mb-[24px]',
  ]),
  textWrapper: 'w-full',
  title: cx([
    'text-[36px]/[40px]',
    'font-bold',
    'text-primary',
    'text-[#000]',
    'xl:text-[#051A3D]',
    'text-center',
    'xl:text-[45px]/[48px]',
    'xl-mid:text-[50px]/[56px]',
    'xl:text-left',
    'mb-[8px]',
    'xl:mb-3.5',
    'tracking-[0.45px]',
  ]),
  ul: cx([
    'flex',
    'flex-col',
    'gap-[4px]',
    'pb-[24px]',
    'xl:pb-[32.5px]',
    's:pl-[50px]',
    'xl:pl-[0]',
  ]),
  li: cx([
    'w-full',
    'text-center',
    'text-[#051A3D]',
    'text-[14px]/[15.4px]',
    'xl:text-[16px]/[19.2px]',
    'font-bold',
    'rounded-[24px]',
    'flex',
    'items-center',
  ]),
  liNumber: cx([
    'w-[32px]',
    'h-[32px]',
    'block',
    'rounded-[24px]',
    'flex',
    'items-center',
    'justify-center',
    'm-[8px]',
    'xl:m-[4px]',
  ]),
  stories: cx([
    'xl:max-w-[316px]',
    'transition',
    'duration-300',
    'hover:opacity-80',
  ]),
  button: cx([
    'xl:w-[241px]',
    'xl:h-[48px]',
    'xl:text-[14px]/[16px]',
    'text-[16px]/[19.2px]',
    'xl:font-bold',
    'font-semibold',
  ]),
  link: cx([
    'mt-3',
    'xl:text-[#034788]',
    'text-white',
    'text-[14px]/[21px]',
    'xl:text-[14px]/[16px]',
    'justify-center',
    'no-underline',
    'xl:underline',
    'font-bold',
  ]),
  buttonGroup: cx([
    'flex',
    'flex-col',
    'justify-end',
    'h-fit',
    'xl:max-w-max',
    'xl:items-center',
  ]),
  girl: cx([
    'w-full',
    'h-full',
    'bottom-[-195px]',
    'right-[-134px]',
    's:bottom-[-180px]',
    's:right-[-137px]',
    'z-[-1]',
    'bg-[url("/images/section-hero/girl.png")]',
    'bg-contain',
    'bg-no-repeat',
    'absolute',
    'xl:hidden',
  ]),
  tradein: cx([
    'bg-[#03478814]',
    'rounded-[16px]',
    'relative',
    'p-[24px]',
    'xl:p-[36px]',
    'overflow-hidden',
    'text-[#051A3D]',
    'text-center',
  ]),
  tradeinTextBlock: cx([
    'flex',
    'flex-col',
    'gap-[8px]',
    'pb-[88px]',
    'xl:pb-[15px]',
  ]),
  tradeinTitle: cx([
    'text-[20px]/[28px]',
    'xl:text-[36px]/[36px]',
    'font-bold',
  ]),
  tradeinDescription: cx([
    'text-[16px]/[20px]',
    'font-normal',
  ]),
  tradeinButton: cx([
    'w-[280px]',
    'h-[48px]',
    'xl:w-[220px]',
    'text-[14px]/[16px]',
    'text-white',
    'font-bold',
    'm-auto',
    'xl:m-auto',
    'z-[5]',
  ]),
  tradeinTgIcon: cx([
    'bg-[url("/assets/icons/socials/telegram-white.svg")]',
    'bg-no-repeat',
    'w-[16px]',
    'h-[13.24px]',
    'ml-[10px]',
  ]),
  tradeinS1: cx([
    'block',
    'absolute',
    'bg-[url("/images/section-hero/12s1.png")]',
    'bg-no-repeat',
    'w-[120px]',
    'h-[236px]',
    'z-[2]',
    'bottom-[-126px]',
    'left-[50px]',
    'xl:bottom-[-130px]',
    'xl:left-[50px]',
    'xl-mid:bottom-[-118px]',
    'xl-mid:left-[71px]',
  ]),
  tradeinS2: cx([
    'block',
    'absolute',
    'bg-[url("/images/section-hero/12s2.png")]',
    'bg-no-repeat',
    'w-[140px]',
    'h-[276px]',
    'z-[3]',
    'bottom-[-133px]',
    'right-[45px]',
    'xl:bottom-[-130px]',
    'xl:right-[50px]',
    'xl-mid:bottom-[-111px]',
    'xl-mid:right-[70px]',
  ]),
  tradeinCoin1: cx([
    'block',
    'absolute',
    'bg-[url("/images/section-hero/coin1.png")]',
    'bg-no-repeat',
    'w-[324px]',
    'h-[162px]',
    'z-[4]',
    'xl:bottom-[20px]',
    'xl:right-[-30px]',
    'xl-mid:right-[-18px]',
    'xl-mid:bottom-[20px]',
  ]),
  tradeinCoin2: cx([
    'block',
    'absolute',
    'bg-[url("/images/section-hero/coin2.png")]',
    'bg-no-repeat',
    'w-[157px]',
    'h-[231px]',
    'z-[4]',
    'xl:bottom-[-70px]',
    'xl:left-[-25px]',
    'xl-mid:left-[-4px]',
    'xl-mid:bottom-[-70px]',
  ]),
  coin0: cx([
    'block',
    'absolute',
    'xl:hidden',
    'bg-[url("/images/section-hero/coin0.png")]',
    'bg-no-repeat',
    'w-[235px]',
    'h-[190px]',
    'bottom-[0]',
    'z-[-1]',
  ]),
}

const viewport = useViewport()
const xlAndLarger = computed(() => viewport.isGreaterOrEquals('xl'))

const utmStore = useUtmStore()

const telegramLink = computed(() => {
  if (!utmStore.utmTags) return ''

  const params = new URLSearchParams({
    start: `pm_${utmStore.utmTags['utm_campaign']}_${xlAndLarger.value ? 'desktop' : 'mobile'}`,
  })

  return `${getTelegramLink(2)}?${params.toString()}`
})

const trackEvent = (socialKey = 'tg') => {
  const eventName = `ti_${socialKey}_bot`

  emitYandexMetrika(YANDEX_METRIKA_GOALS.TI_TG_BOT)
  emitYandexMetrika(eventName)
  emitMixpanelEvent(eventName)
}
</script>

<style>
.gradient {
  background: linear-gradient(90deg, rgba(3, 71, 136, 0.08) 0%, rgba(242, 247, 252, 0) 100%);
}
.gradientNumber {
  background: var(--bg-main-thirdly, #03478814);
}
</style>